




















































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
})

/**
 * circleActiveClass: string (optional)
 * circleInactiveClass: string (optional)
 *
 * You can change the color of circle's unchecked and checked state by using circleActiveClass and circleInactiveClass
 * example: circleActiveClass="bg-green-600" circleInactiveClass="bg-red-600"
 *
 * bodyActiveClass: string (optional)
 * bodyInactiveClass: string (optional)
 *
 * You can change the color of toogle body's unchecked and checked state by using bodyActiveClass and bodyInactiveClass
 * example: bodyActiveClass="bg-green-600" bodyInactiveClass="bg-red-600"
 *
 * bodyBorderClass: string (optional)
 *
 * Change border appearance
 * example: bodyBorderClass="border border-blue-800"
 *
 */
export default class Toggle extends Vue {
  @Prop({ required: true }) private id!: string | number
  @Prop({ default: false }) private checked!: boolean
  @Prop({ default: 'h-8' }) private circleHeightClass!: string
  @Prop({ default: 'w-8' }) private circleWidthClass!: string
  @Prop({ default: 'h-10' }) private bodyHeightClass!: string
  @Prop({ default: 'w-16' }) private bodyWidthClass!: string
  @Prop({ type: String }) private circleActiveClass!: string
  @Prop({ type: String }) private circleInactiveClass!: string
  @Prop({ type: String }) private bodyActiveClass!: string
  @Prop({ type: String }) private bodyInactiveClass!: string
  @Prop({ type: String, default: 'border-2 border-gray-300' })
  private bodyBorderClass!: string

  get circleClass(): string {
    if (this.checked && this.circleActiveClass) {
      return this.circleActiveClass
    }

    if (!this.checked && this.circleInactiveClass) {
      return this.circleInactiveClass
    }

    return 'toggle-checkbox-default'
  }

  get bodyClass(): string {
    if (this.checked && this.bodyActiveClass) {
      return this.bodyActiveClass
    }

    if (!this.checked && this.bodyInactiveClass) {
      return this.bodyInactiveClass
    }

    return 'toggle-label'
  }
}
