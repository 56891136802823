import {
  AccessMenuRequestInterface,
  CreateUserRequestInterface,
  UpdateUserRequestInterface,
} from '@/data/payload/contracts/UserManagementRequest'

export class CreateUserRequest implements CreateUserRequestInterface {
  private email: string
  private role: string
  private accessMenu: number[]

  constructor(email: string, role: string, accessMenu: number[]) {
    this.email = email
    this.role = role
    this.accessMenu = accessMenu
  }

  public toPayload(): string {
    return JSON.stringify({
      email: this.email,
      role: this.role,
      access_menu: this.accessMenu,
    })
  }
}

export class UpdateUserRequest implements UpdateUserRequestInterface {
  private role: string
  private accessMenu: number[]

  constructor(role: string, accessMenu: number[]) {
    this.role = role
    this.accessMenu = accessMenu
  }

  public toPayload(): string {
    return JSON.stringify({
      role: this.role,
      access_menu: this.accessMenu,
    })
  }
}

export class AccessMenuRequest implements AccessMenuRequestInterface {
  private slug: string
  private name: string
  private group: string

  constructor(slug: string, name: string, group: string) {
    this.slug = slug
    this.name = name
    this.group = group
  }

  public toPayload(): string {
    return JSON.stringify({
      slug: this.slug,
      name: this.name,
      group: this.group,
    })
  }
}
