var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center",attrs:{"data-testid":("" + (_vm.$attrs['data-testid']))}},[_c('div',{class:[
      'relative inline-block mr-2 align-middle select-none transition duration-200 ease-in',
      _vm.bodyWidthClass ]},[_c('input',{class:[
        'outline-none absolute block m-1 rounded-full appearance-none toggle-checkbox',
        _vm.circleHeightClass,
        _vm.circleWidthClass,
        _vm.circleClass,
        _vm.$attrs.disabled ? 'cursor-not-allowed' : 'cursor-pointer' ],attrs:{"data-testid":"global-component-toggle-switcher__input","disabled":_vm.$attrs.disabled,"type":"checkbox","name":("toggle-" + _vm.id),"id":("toggle-" + _vm.id)},domProps:{"checked":_vm.checked},on:{"change":function($event){return _vm.$emit('change', $event)},"click":function($event){return _vm.$emit('click', $event)}}}),_c('label',{class:[
        'block overflow-hidden rounded-full',
        _vm.bodyHeightClass,
        _vm.bodyClass,
        _vm.bodyBorderClass,
        _vm.$attrs.disabled ? 'cursor-not-allowed' : 'cursor-pointer' ],attrs:{"data-testid":"global-component-toggle-switcher__label","for":("toggle-" + _vm.id)}})]),_c('label',{attrs:{"for":("toggle-" + _vm.id),"data-testid":"global-component-toggle-switcher__label-checked"}},[(_vm.checked)?_vm._t("checked"):_vm._t("unchecked")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }