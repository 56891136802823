














import { Component, Prop, Vue } from 'vue-property-decorator'
import Toggle from '@/app/ui/components/Toggle/index.vue'

@Component({
  inheritAttrs: false,
  components: {
    Toggle,
  },
})
export default class RoleToggle extends Vue {
  @Prop({ default: '', required: true }) text!: string
}
